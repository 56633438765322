import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./userSinglePost.module.css";
import CommonBanner from "../../../components/CommonBanner";
import PreFooterSection from "../../../components/PreFooterSection";
import BlogComponent from "../../../components/BlogComponent/BlogComponent";
import Footer from "../../../components/Footer";
import EditUserPostOverlay from "../../../components/OverlayContainer/EditUserPostOverlayContainer";
import { deletePostQuery } from "../../../utils/apiQuery";

const UserSinglePost = () => {
  const authToken = JSON.parse(localStorage.getItem("user"))?.token;
  const { id } = useParams();
  const navigate = useNavigate();
  const singlePost = JSON.parse(localStorage.getItem(id));
  const [show, setShow] = useState(false);
  // const ourData = useSelector((state) => state.userPost.userPost);
  // const singlePost = ourData?.filter((item) => +item.id === +id)[0];
  const paramPassInCommonBanner = `Post > ${singlePost?.heading}`;

  const onHandleEdit = () => {
    setShow((i) => !i);
  };
  const fetchData = async () => {
    const response = await deletePostQuery(singlePost, authToken);
    const res = await response.json();
    console.log("Delete-Post-Res", res);
    navigate("/admin/post");
  };

  const onHandleDelete = () => {
    fetchData();
  };

  return (
    <>
      {/* <Navbar login={true} /> */}
      {show && (
        <EditUserPostOverlay
          handleOnClick={onHandleEdit}
          // userHeading={singlePost.heading}
          // userParagraph={singlePost.paragraph}
          singlePost={singlePost}
        />
      )}
      <CommonBanner
        currentPage={paramPassInCommonBanner}
        heading={singlePost?.heading}
      />
      <div
        style={{
          background: "white",
          height: "30px",
          // border: "2px solid red",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5% 5% 0% 5%",
        }}
      >
        <button className={styles.button_edit_container} onClick={onHandleEdit}>
          <img
            src="https://cdn-icons-png.flaticon.com/512/1250/1250615.png"
            alt=""
            width={15}
          />
          EDIT
        </button>
        <button
          className={styles.button_delete_container}
          onClick={onHandleDelete}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/8719/8719265.png"
            alt=""
            width={15}
          />
          Delete
        </button>
      </div>
      <BlogComponent singlePost={singlePost} />
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default UserSinglePost;
