import React from "react";
import styles from "./ourBrand.module.css";

const OurBrand = ({ brand }) => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {Object.values(brand).map((item, i) => {
          if (!item.link)
            return (
              <div className={styles.card} key={i}>
                <img
                  style={{
                    transform:
                      item.title === "RNR Industries"
                        ? "scale(2)"
                        : item.title === "HARSHU" && "scale(1.5)",
                  }}
                  className={styles.image}
                  src={`${process.env.PUBLIC_URL}${item.image}`}
                  alt={item.title}
                />
                {!item.link && (
                  <div className={styles.title} style={{ color: "black" }}>
                    Coming Soon!!
                  </div>
                )}
              </div>
            );
          else
            return (
              <a href={item.link} rel="noopener" target="_blank">
                <img
                  style={{
                    transform:
                      item.title === "RNR Industries"
                        ? "scale(2)"
                        : item.title === "HARSHU" && "scale(1.5)",
                  }}
                  alt={item.title}
                  className={styles.image}
                  src={`${process.env.PUBLIC_URL}${item.image}`}
                />
              </a>
            );
        })}
      </div>
    </div>
  );
};

export default OurBrand;
