import React from "react";

const AllServicesCommonSection = ({ header, strong, description }) => {
  return (
    <div className="all_services_common_section_container">
      <div className="all_services_common_section_left">
        <div className="all_services_common_section_left_upper">{header}</div>
        <div className="all_services_common_section_left_lower">
          <div>
            {strong !== "" && <strong>{strong} </strong>}
            {description !== "" && description}
          </div>
        </div>
        <div></div>
      </div>
      {/* <div className="all_services_common_section_right"></div> */}
    </div>
  );
};

export default AllServicesCommonSection;
