import React from "react";

const ValidationMessageError = ({ props }) => {
  return (
    <div
      style={{
        fontSize: "10px",
        color: "red",
        fontWeight: "600",
        margin: "2px 0 0 5px",
      }}
    >
      {props}
    </div>
  );
};

export default ValidationMessageError;
