import React, { useEffect, useState } from "react";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";
import styles from "./dashboard.module.css";
import BlogCarousel from "../../../components/Carousel/BlogCarousel";
// import { post } from "../../../constant/post";
import CommonBanner from "../../../components/CommonBanner";
import { useDispatch, useSelector } from "react-redux";
import { addAllUserPost, reset } from "../../../Redux/Action/userPostAction";
import { useNavigate } from "react-router-dom";
import UserProfileEditOverlay from "../../../components/OverlayContainer/UserProfileEditOverlayContainer";
import {
  getAllUserPostQuery,
  getNewContactUsDetail,
} from "../../../utils/apiQuery";
import SpinLoader from "../../../components/Loader/SpinLoader";
import ContactUsCarousel from "../../../components/Carousel/ContactUsCarousel";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [contactUsData, setContactUsData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = JSON.parse(localStorage.getItem("user"));
  const ourData = useSelector((state) => state.userPost.userPost);
  const [isMounted, setIsMounted] = useState(true);

  const paramPassInCommonBanner = `Dashboard`;
  const fetchData = async () => {
    try {
      // await getNewContactUsDetail(userDetail.token);
      const response = await getAllUserPostQuery(userDetail.token);
      const res = await response.json();
      if (res.success === 0) {
        localStorage.clear();
        navigate("/admin/login");
      } else {
        if (isMounted) {
          setLoading(false);
          dispatch(reset());
          dispatch(addAllUserPost(res.data));
          // setOurData(res.data);
        }
      }
      setLoading(false);
    } catch (error) {
      navigate("/admin/login");
    }
  };

  const fetchContactUsData = async () => {
    try {
      const response = await getNewContactUsDetail(userDetail.token);
      const res = await response.json();
      if (res.success === 0) {
      } else {
        if (isMounted) {
          setLoading2(false);
          setContactUsData(res.data);
        }
      }
      setLoading2(false);
    } catch (error) {
      console.error("error while fetching contact us detail=>", error);
    }
  };

  useEffect(() => {
    fetchContactUsData();
    setLoading(true);
    setLoading2(true);
    setIsMounted(true);
    fetchData();
    return () => {
      // Cleanup function to be called when the component is unmounted or before running the effect again
      setIsMounted(false); // Set the flag to false to indicate the component is unmounted
    };
  }, []);

  const handleOnCloseOverlay = () => {
    setShow((i) => !i);
  };

  return (
    <div>
      {/* <Navbar login={true} /> */}
      {show && (
        <UserProfileEditOverlay handleOnCloseOverlay={handleOnCloseOverlay} />
      )}
      <CommonBanner
        currentPage={paramPassInCommonBanner}
        heading={`${userDetail?.firstName} ${userDetail?.lastName}`}
      />
      <div className={styles.profile_container}>
        <div className={styles.profile_top_container}>
          <div className={styles.text_container}>
            <div className={styles.edit} onClick={() => {}}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1827/1827933.png"
                alt=""
                srcSet=""
                width={20}
                onClick={handleOnCloseOverlay}
              />
            </div>
            <div className={styles.name}>
              {userDetail?.firstName} {userDetail?.lastName}
            </div>
            <div className={styles.email_number}>
              <div className={styles.email}>{userDetail?.email}</div>
              <div className={styles.number}>{userDetail?.number}</div>
            </div>
          </div>
        </div>
        <div className={styles.user_carousel_section}>
          <div className={styles.user_carousel_heading}>NEWS & BLOG</div>
          <div className={styles.user_carousel_title}>RECENT NEWS & EVENTS</div>
          <div className={styles.user_carousel_container}>
            {loading ? (
              <SpinLoader />
            ) : ourData.length > 0 ? (
              <BlogCarousel post={ourData} admin={true} />
            ) : (
              <div>No Post Is For Preview</div>
            )}
          </div>
        </div>
        <div className={styles.user_carousel_section}>
          <div className={styles.user_carousel_heading}>CONTACT US</div>
          <div className={styles.user_carousel_title}>New Clients Messages</div>
          <div className={styles.user_carousel_container}>
            {loading2 ? (
              <SpinLoader />
            ) : ourData.length > 0 ? (
              <ContactUsCarousel data={contactUsData} />
            ) : (
              <div>Client Message Is Not Present</div>
            )}
          </div>
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Dashboard;
