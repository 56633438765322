import React, { useState } from "react";
import ContactOverlayContainer from "./OverlayContainer/ContactOverlayContainer";
import { useNavigate } from "react-router-dom";

const PreFooterSection = () => {
  const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  // const handleOnClick = () => {
  //   setShow((s) => !s);
  // };
  return (
    <div className="prefooterSection">
      <div className="prefooterSection_left">
        <div className="prefooterSection_heading">
          <div
            className="triangle"
            style={{ borderColor: "transparent white white transparent" }}
          ></div>
          DO YOU HAVE ANY PROJECTS?
        </div>
        <div className="prefooterSection_title">
          Let's Talk About Business Solutions with Our Team
        </div>
      </div>
      <div className="prefooterSection_right">
        <button
          className="prefooterSection_right_contact_button"
          // onClick={handleOnClick}
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Contact Us Now
        </button>
        <p style={{ color: "white" }}>(909) 869-1173</p>
        <span>
          <img
            src="https://cdn-icons-png.flaticon.com/512/1176/1176894.png"
            alt=""
            srcSet=""
            width={16}
            style={{ filter: "invert(1)" }}
          />
        </span>
      </div>
      {/* {show && <ContactOverlayContainer handleOnClick={handleOnClick} />} */}
    </div>
  );
};

export default PreFooterSection;
