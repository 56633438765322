import React, { useState } from "react";
import { services } from "../constant/service";
import { addNewContactUsDetail } from "../utils/apiQuery";
import ValidationMessageError from "./ValidationMessageError";
import { checkFormValidation } from "../utils/checkValidationform";

const ContactForm = () => {
  const initialData = {
    id: "",
    name: "",
    contact: "",
    email: "",
    service: "",
    message: "",
  };
  const [option, setOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState("select option");
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const [errorService, setErrorService] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [formData, setFormData] = useState(initialData);
  const { name, contact, email, message } = formData;

  const handleOnChange = (event) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidNumber = /^[0-9\b]+$/;
    if (event.target.name === "name") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorName]);
    }

    if (event.target.name === "email") {
      const condition = event.target.value.match(isValidEmail);
      checkFormValidation([condition, setErrorEmail]);
    }
    if (event.target.name === "contact") {
      const condition =
        event.target.value.length === 10 &&
        event.target.value.match(isValidNumber);
      checkFormValidation([condition, setErrorContact]);
    }

    if (event.target.name === "service") {
      const condition = event.target.value !== "select option";
      checkFormValidation([condition, setErrorService]);
    }

    if (event.target.name === "message") {
      const condition = event.target.value.length > 0;
      checkFormValidation([condition, setErrorMessage]);
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        !errorName &&
        !errorEmail &&
        !errorContact &&
        !errorService &&
        !errorMessage
      ) {
        const newPost = {
          ...formData,
          id: Date.now(),
        };
        const response = await addNewContactUsDetail(newPost);
        const data = await response.json();
        if (data.success === 1) {
          setFormData(initialData);
          setSelectedOption("select option");
        } else {
          alert("server is busy, please try again later");
        }
      } else {
        alert("Fill The Form Correctly");
      }
    } catch (error) {
      console.error("server is busy", error);
    }
  };

  const handleClick = () => {
    setOption((i) => !i);
  };

  return (
    <form onSubmit={handleOnSubmit} className="contact_form_container">
      <div className="form_input_container">
        <label htmlFor="">Name</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={handleOnChange}
          required
        />
        {errorName && (
          <ValidationMessageError
            props={"Enter Your Name With 255 character"}
          />
        )}
      </div>
      <div className="form_input_container">
        <label htmlFor="">Email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleOnChange}
          required
        />
        {errorEmail && <ValidationMessageError props={"Enter Correct Email"} />}
      </div>
      <div className="form_input_container">
        <label htmlFor="">Phone</label>
        <input
          type="text"
          name="contact"
          value={contact}
          onChange={handleOnChange}
          required
        />
        {errorContact && (
          <ValidationMessageError props={"Enter Valid Phone Number"} />
        )}
      </div>
      <div className="form_input_container">
        <label htmlFor="">Services</label>
        <div className="radio" onClick={handleClick}>
          {selectedOption}
        </div>
        {option && (
          <div className="form_radio_option">
            {services.map((item, i) => (
              <div
                key={i}
                onClick={() => {
                  setSelectedOption(item.title);
                  setFormData({ ...formData, service: item.title });
                  setOption(false);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
        )}
        {errorService && (
          <ValidationMessageError
            props={"Please Select From The Given Option"}
          />
        )}
      </div>
      <div className="form_input_container">
        <label htmlFor="">Your Message</label>
        <textarea
          name="message"
          id="message"
          cols="50"
          rows="5"
          value={message}
          onChange={handleOnChange}
          required
        />
        {errorMessage && (
          <ValidationMessageError props={"Enter Your Message Here"} />
        )}
      </div>
      <div className="form_input_container_button">
        <input type="submit" value="Submit" name="" onChange={handleOnChange} />
      </div>
    </form>
  );
};

export default ContactForm;
