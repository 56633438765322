import React from "react";
import CommonBanner from "../../../components/CommonBanner";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";

const Client = () => {
  const client = JSON.parse(localStorage.getItem("client"));
  const paramPassInCommonBanner = `New Client`;
  const userDetail = JSON.parse(localStorage.getItem("user"));
  return (
    <div>
      <CommonBanner
        currentPage={paramPassInCommonBanner}
        heading={`${userDetail?.firstName} ${userDetail?.lastName}`}
      />
      <div className="webdesigndevelopment_card_container">
        <div className="webdesigndevelopment__card_icon">
          <img
            src="https://cdn-icons-png.flaticon.com/512/476/476700.png"
            alt=""
            srcSet=""
          />
        </div>
        <div className="section_3_card_title">{client.services}</div>
        <div className="webdesigndevelopment_card_description">
          {client.name}
        </div>
        <div className="webdesigndevelopment_card_description">
          {client.contact}
        </div>
        <div className="webdesigndevelopment_card_description">
          {client.image}
        </div>
        <div className="webdesigndevelopment_card_description">
          {client.message}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Client;
