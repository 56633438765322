import React, { useState } from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import { checkFormValidation } from "../../utils/checkValidationform";
import ValidationMessageError from "../../components/ValidationMessageError";

const Careers = () => {
  const initialData = {
    firstName: "",
    secondName: "",
    contact: "",
    email: "",
    address: "",
  };
  const [resume, setResume] = useState(null);
  const [formData, setFormData] = useState(initialData);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorSecondName, setErrorSecondName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);

  // const [file, setFile] = useState(null);

  const onHandleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        !errorFirstName &&
        !errorSecondName &&
        !errorEmail &&
        !errorContact &&
        !errorAddress
      ) {
        const data = new FormData();

        for (var key in formData) {
          data.append(key, formData[key]);
        }

        data.append("resume", resume);

        const response = await fetch(
          "https://bni-backend.onrender.com/api/resume/mail",
          {
            method: "POST",
            body: data,
          }
        );
        const getRes = await response.json();
        console.log(getRes);
        // second form send function :-
        // Add any success message or additional actions here.
      } else {
        alert("Fill The Form Completely");
      }
    } catch (error) {
      console.error("Error uploading the resume:", error);
      // Handle error and show an appropriate message to the user.
    }
  };

  // const handleOnChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  const handleOnChange = (event) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidNumber = /^[0-9\b]+$/;

    if (event.target.name === "firstName") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorFirstName]);
    }
    if (event.target.name === "secondName") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorSecondName]);
    }

    if (event.target.name === "email") {
      const condition = event.target.value.match(isValidEmail);
      checkFormValidation([condition, setErrorEmail]);
    }
    if (event.target.name === "contact") {
      const condition =
        event.target.value.length === 10 &&
        event.target.value.match(isValidNumber);
      checkFormValidation([condition, setErrorContact]);
    }
    if (event.target.name === "address") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorAddress]);
    }

    if (event.target.name === "resume") {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };
  const { firstName, secondName, contact, email, address } = formData;

  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner currentPage="Careers" heading="Careers" />
      <div className="career_section_3_container">
        <div className="career_section_3_left">
          <div className="career_section_3_left_upper">
            <div className="career_section_3_left_upper_p_1">
              <div className="triangle"></div>
              CAREER IN BUSINESS NEEDS INC.
            </div>
            <div className="career_section_3_left_upper_p_2">
              Fill Your Details Below
            </div>
          </div>
          <div className="career_section_3_left_lower">
            <form
              onSubmit={onHandleSubmit}
              className="career_section_3_form_container"
            >
              <div className="career_section_3_form_input_double_container">
                <div className="career_section_3_form_input_container">
                  <div>
                    First Name<sup style={{ fontSize: "15px" }}>*</sup>
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    id=""
                    onChange={handleOnChange}
                  />
                  {errorFirstName && (
                    <ValidationMessageError
                      props={"Enter Your Name With 255 character"}
                    />
                  )}
                </div>
                <div className="career_section_3_form_input_container">
                  <div>
                    Last Name<sup style={{ fontSize: "15px" }}>*</sup>
                  </div>
                  <input
                    type="text"
                    name="secondName"
                    value={secondName}
                    id=""
                    onChange={handleOnChange}
                  />
                  {errorSecondName && (
                    <ValidationMessageError
                      props={"Enter Your Name With 255 character"}
                    />
                  )}
                </div>
              </div>
              <div className="career_section_3_form_input_double_container">
                <div className="career_section_3_form_input_container">
                  <div>
                    Phone Number<sup style={{ fontSize: "15px" }}>*</sup>
                  </div>
                  <input
                    type="text"
                    name="contact"
                    value={contact}
                    id=""
                    onChange={handleOnChange}
                  />
                  {errorContact && (
                    <ValidationMessageError
                      props={"Enter Valid Phone Number"}
                    />
                  )}
                </div>
                <div className="career_section_3_form_input_container">
                  <div>
                    Email<sup style={{ fontSize: "15px" }}>*</sup>
                  </div>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    id=""
                    onChange={handleOnChange}
                  />
                  {errorEmail && (
                    <ValidationMessageError props={"Enter Correct Email"} />
                  )}
                </div>
              </div>
              <div className="career_section_3_form_input_single_container">
                <div>
                  Address<sup style={{ fontSize: "15px" }}>*</sup>
                </div>
                <input
                  type="text"
                  name="address"
                  value={address}
                  id=""
                  onChange={handleOnChange}
                />
                {errorAddress && (
                  <ValidationMessageError
                    props={"Enter Your Name With 255 character"}
                  />
                )}
              </div>
              <div className="career_section_3_form_input_single_container">
                <div>Resume</div>
                <input
                  type="file"
                  name="resume"
                  id="resume"
                  accept=".pdf"
                  onChange={(e) => setResume(e.target.files[0])}
                  style={{ border: "none" }}
                />
              </div>
              <div className="career_section_3_form_submit_button">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
        <div className="career_section_3_right">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:6851518521692815360"
            height="582"
            width="100%"
            frameborder="0"
            allowfullscreen=""
            title="Embedded post"
          ></iframe>
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default Careers;
