import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";

const DatabaseManagement = () => {
  const header =
    "Maximize Your Revenue Potential with Custom Database Development and Management";
  const strong = "";
  const description =
    "Efficient data management is key to the success of any business. At Business Needs Inc., we specialize in custom database development and management solutions that can help your business maximize its potential. Our team of experts will work with you to identify your unique data needs and create a tailored solution that fits your business perfectly. With our database management services, you can streamline your operations, increase efficiency, and optimize your revenue potential.";
  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="DATABASE MANAGEMENT"
        heading="DATABASE MANAGEMENT"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default DatabaseManagement;
