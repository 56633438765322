export const Brand = {
  1: {
    image: "https://riyuglobal.com/cdn/shop/files/RIYU_logo_png.png",
    whiteImage:
      "https://riyuglobal.com/cdn/shop/files/MicrosoftTeams-image_6.png",
    link: "https://riyuglobal.com/",
    title: "RIYU",
  },
  2: {
    image: "/images/RNRLOGO.png",
    link: "http://rrindustriesus.com/",
    title: "RNR Industries",
  },
  3: {
    image:
      "https://www.harshuglobal.com/cdn/shop/files/harshu-logo-crop.png",
    link: "https://harshuglobal.com/",
    title: "HARSHU",
  },
};
