import React, { useEffect, useState } from "react";
import styles from "./scrollToTop.module.css";

const ScrollToTop = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 400) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (show)
    return (
      <button className={styles.scroll_to_top} onClick={handleClick}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/2697/2697212.png"
          alt="scroll to top"
          srcSet=""
        />
      </button>
    );
};

export default ScrollToTop;
