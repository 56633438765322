import React, { useState } from "react";
import styles from "./SearchPagePopUp.module.css";

const SearchPagePopUp = ({ searchWrapperShow, setSearchWrapperShow }) => {
  const [searchData, setSearchData] = useState([]);
  const data = [];
  const handleChange = (e) => {
    // console.log("e", e.target.value);
    const newData = data.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchData(newData);
  };
  return (
    <div className={styles.open}>
      <div
        className={styles.cancel}
        onClick={() => setSearchWrapperShow(false)}
      >
        ✖️
      </div>
      <input
        className={styles.search_input}
        placeholder="Search"
        type="search"
        name="search"
        autoFocus
        onChange={handleChange}
      />
      <div className={styles.container}>
        {searchData.map((item) => {
          return (
            <div key={item.id} className={styles.card}>
              <h3>{item.name}</h3>
              <p>{item.language}</p>
              <p>{item.bio}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchPagePopUp;
