import React from "react";
import { useParams } from "react-router-dom";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import BlogComponent from "../../components/BlogComponent/BlogComponent";

const BlogPage = () => {
  const { id } = useParams();
  const singlePost = JSON.parse(localStorage.getItem(id));
  const paramPassInCommonBanner = `News > ${singlePost.heading}`;

  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage={paramPassInCommonBanner}
        heading={singlePost.heading}
      />
      <BlogComponent singlePost={singlePost} />
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default BlogPage;
