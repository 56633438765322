export const services = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2721/2721688.png",
    title: "IT Transformation",
    description:
      "Transform your business processes and gain a competitive edge while reducing costs",
    link: "/services/ittransformation",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2906/2906206.png",
    title: "Cloud Transition",
    description:
      " Elevate your business with cloud transition services that prioritize privacy, security, and data management",
    link: "/services/cloudtransition",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2335/2335265.png",
    title: "Web Design & Development",
    description:
      "Enhance your online presence and attract more customers with our professional web design and development services.",
    link: "/services/webdesignanddevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11357/11357467.png",
    title: "Software Development",
    description:
      " Maximize your business potential with software that is tailored to your unique needs that enhances your operations.",
    link: "/services/softwaredevelopment",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1900/1900194.png",
    title: "IT Professional Staffing",
    description:
      "Get ready to take your team to new heights with our expert recruiting staff - whether you need short-term or long-term staffing solutions",
    link: "/services/itprofessionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/3246/3246843.png",
    title: "IT Managed Services",
    description:
      "Ensure that your technology needs are handled effectively, allowing you to focus on your core business",
    link: "/services/itprofessionalstaffing",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1119/1119007.png",
    title: "Application Security",
    description:
      "Protect your systems from external threats with our expert application security services",
    link: "/services/applicationsecurity",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9708/9708984.png",
    title: "Database Management",
    description:
      "Maximize your revenue and streamline your operations with our expert data management services.",
    link: "/services/databasemanagement",
  },
];

export const AccountingServicesData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8913/8913116.png",
    title: "Accounts Payable",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/7591/7591422.png",
    title: "Taxation",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/6057/6057901.png",
    title: "book keeping & accountancy",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/4306/4306889.png",
    title: "Invoicing",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10930/10930823.png",
    title: "Bank Audit (Monthly & Quarterly)",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Inventory Audit",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11139/11139294.png",
    title: "Sale Forcasting",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8913/8913116.png",
    title: "W2 Payrolls",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1159/1159633.png",
    title: "1099",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/6698/6698239.png",
    title: "Accounts Receivable",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11094/11094266.png",
    title: "Payment Tracking",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10216/10216823.png",
    title: "Bank Reconciliation. (Debit card & Credit cards)",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11482/11482372.png",
    title: "Sale Orders",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11518/11518892.png",
    title: "Inventory Management",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/11268/11268992.png",
    title: "Budget Accounts Management",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/5441/5441161.png",
    title: "Sale Tax Deposit Quarterly or yearly",
    description: "",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/7377/7377207.png",
    title: "Payrolls Deposit weekly or biweekly",
    description: "",
  },
];

export const ApplicationSecurityData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/8299/8299880.png",
    title: "Security Inbox",
    description:
      "Replacing your security@ email address with our services limits your risk and provides centralized access to your vulnerability reports.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/9299/9299850.png",
    title: "Automated Workflows",
    description:
      "Workflow integrations and an advanced API allow security and engineering teams to avoid changing the way they work.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/10816/10816122.png",
    title: "On-Demand and Continuous Testing",
    description:
      "Whether looking for a project-based test or a continuous 365/24/7 security solution, we can create a program to fit your needs.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/5455/5455956.png",
    title: "Self-Managed vs Fully-Managed",
    description:
      "We are the only provider to offer the choice of managing a program on your own or leveraging our ‚Äòwhite-glove‚Äô services.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Actionable & Compliant",
    description:
      "Business Needs Inc can create a dashboard to consolidate your vulnerability data to identify risks before they can be exploited.",
  },
];

export const WebDesignAndDevelopmentData = [
  {
    icon: "https://cdn-icons-png.flaticon.com/512/1006/1006585.png",
    title: "Strategy",
    description:
      "Every business has different needs. The team at Business Needs Inc meet those needs with a combination of vision and tons of experience. We can help your business conceive and implement a branding and marketing strategy that will exceed your expectations.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/2421/2421341.png",
    title: "Stunning Creative Design",
    description:
      "Building quality UX designs really gets our creative juices flowing. Every website should be a visual work of art, with functionality and a vision that reflects your image and brand. We create every website to stand out from the crowd and be as unique as the company it represents.",
  },
  {
    icon: "https://cdn-icons-png.flaticon.com/512/897/897219.png",
    title: "Technology",
    description:
      "Finding new and innovative ways to develop websites and mobile apps is constantly evolving. From CMS and API integration to the simplest responsive design, our website tech specialist produce the best solutions for you.",
  },
];
