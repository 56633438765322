import React, { useState } from "react";
import styles from "./addBlogOverlayContainer.module.css";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUserPost } from "../../Redux/Action/userPostAction";
import { updatePostQuery } from "../../utils/apiQuery";

const EditUserPostOverlay = ({ handleOnClick, singlePost }) => {
  const authToken = JSON.parse(localStorage.getItem("user"))?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(singlePost.paragraph))
    )
  );
  console.log("singlePost=>", singlePost);
  const [formData, setFormData] = useState({
    heading: singlePost.heading,
    paragraph: singlePost.paragraph,
    time: singlePost.time,
    id: singlePost.id,
  });

  const { heading } = formData;

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onHandleSetEditor = (newState) => {
    setEditorState(newState);
    setFormData({
      ...formData,
      paragraph: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const time = `${currentDate.getDate()} ${
      month[currentDate.getMonth()]
    } ${currentDate.getFullYear()}`;
    const newPost = {
      ...formData,
      time: time,
    };
    const response = await updatePostQuery(newPost, authToken);
    const data = await response.json();
    if (data.success === 1) {
      dispatch(addUserPost(newPost));
      setFormData({
        heading: "",
        paragraph: "",
      });
      handleOnClick();
      navigate("/admin/post");
    } else {
    }
  };

  return (
    <>
      <div className={styles.contact_overlay_form_div}>
        <button
          onClick={handleOnClick}
          className={styles.contact_overlay_cross_button}
        >
          x
        </button>
        <form className={styles.form} onSubmit={handleOnSubmit}>
          <div className={styles.title}>Edit blog</div>
          {/* <div className={styles.subtitle}>Edit Blog</div> */}
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="heading"
              className={styles.input}
              type="heading"
              value={heading}
              placeholder="Heading"
              name="heading"
              onChange={handleOnChange}
            />
          </div>
          <div className={`${styles.input_para_container} ${styles.ic1}`}>
            <Editor
              defaultEditorState={editorState}
              onEditorStateChange={onHandleSetEditor}
              wrapperClassName={styles.wrapper_class}
              editorClassName={styles.editor_class}
              toolbarClassName={styles.toolbar_class}
              toolbar={{
                options: ["inline", "list", "textAlign", "history"],
                // inline: { inDropdown: true },
                // list: { inDropdown: true },
                // textAlign: { inDropdown: true },
                // link: { inDropdown: true },
                // history: { inDropdown: true },
              }}
            />
            {/* <label htmlFor="paragraph" className={styles.placeholder}>
              Paragraph
            </label> */}
          </div>
          <input className={styles.submit} type="submit" value="Submit" />
        </form>
      </div>
    </>
  );
};

export default EditUserPostOverlay;
