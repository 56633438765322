import React, { useState, useEffect } from "react";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { loginQuery } from "../../../utils/apiQuery";
import SpinLoader from "../../../components/Loader/SpinLoader";
// import {
//   addUser,
//   userDetailSelector,
// } from "../../Redux/Action/userDetailAction";
// import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const authToken = JSON.parse(localStorage.getItem("user"))?.token;
  // const userDetail = useSelector((state) => state.userDetail);
  // const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const { email, password } = formData;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fetchLogin = async () => {
      try {
        const response = await loginQuery(formData);
        const data = await response.json();
        // dispatch(addUser(data));
        setLoading(false);
        const user = {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          number: data.number,
          token: data.token,
        };
        if (data.success === 1) {
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/admin");
        } else {
          alert("wrong credentials");
        }
      } catch (error) {
        alert("Try Again Later!!");
      }
    };
    fetchLogin();
    // if (json.success) {
    //   // Save the auth token and redirect
    //   setLoading(false);
    //   localStorage.setItem("token", json.token);
    //   // navigate("/");
    // } else {
    //   alert("Invalid credentials");
    //   setLoading(false);
    // }
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnClickForgetPassword = () => {
    console.log("handle on click");
    navigate("/admin/forgetpassword");
  };

  useEffect(() => {
    if (!!authToken) {
      navigate("/admin");
    }
  }, []);

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <div>
      {/* <Navbar /> */}
      <div className={styles.form_container}>
        {!!loading ? (
          <SpinLoader />
        ) : (
          <form className={styles.form} onSubmit={handleOnSubmit}>
            <div className={styles.title}>Welcome</div>
            <div className={styles.subtitle}>Login to your account!</div>
            <div className={`${styles.input_container} ${styles.ic1}`}>
              <input
                id="email"
                className={styles.input}
                type="email"
                value={email}
                placeholder=" "
                name="email"
                onChange={handleOnChange}
              />
              <div className={styles.cut}></div>
              <label htmlFor="email" className={styles.placeholder}>
                Email
              </label>
            </div>
            <div className={`${styles.input_container} ${styles.ic1}`}>
              <input
                id="password"
                className={styles.input}
                type={!passwordShow ? "password" : "text"}
                placeholder=" "
                value={password}
                name="password"
                onChange={handleOnChange}
                autoComplete="off"
              />
              <div className={styles.cut}></div>
              <label htmlFor="password" className={styles.placeholder}>
                Password
              </label>
              {/* <label
                htmlFor="password"
                style={{
                  color: "white",
                  position: "absolute",
                  right: "4%",
                  top: "37%",
                }}
              >
                Password
              </label> */}
              {!passwordShow ? (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2767/2767146.png"
                  alt=""
                  srcSet=""
                  style={{
                    color: "white",
                    position: "absolute",
                    right: "4%",
                    top: "37%",
                    filter: "invert(1)",
                    width: "5%",
                    maxWidth: "20px",
                    maxHeight: "20px",
                  }}
                  onClick={() => {
                    setPasswordShow((res) => !res);
                  }}
                />
              ) : (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2767/2767194.png"
                  alt=""
                  srcSet=""
                  style={{
                    color: "white",
                    position: "absolute",
                    right: "4%",
                    top: "37%",
                    filter: "invert(1)",
                    width: "5%",
                    maxWidth: "20px",
                    maxHeight: "20px",
                  }}
                  onClick={() => {
                    setPasswordShow((res) => !res);
                  }}
                />
              )}
            </div>
            <div
              className={styles.forget_password}
              onClick={handleOnClickForgetPassword}
            >
              Forget password?
            </div>
            <input className={styles.submit} type="submit" value="Submit" />
          </form>
        )}
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Login;
