import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";

const ItTransformation = () => {
  const header = " Affordable IT Solutions for Competitive Advantage";
  const strong = "";
  // const description =
  //   "of your company information technology (IT) systems. IT transformation can involve changes to network architecture, hardware, software and how data is stored and accessed. This is all completed for you in order to decrease costs while increasing efficiency and allow you to better compete within your business \n \n Let our expert diagnosis were your company can improve its operations, efficiency and bottom line. As with any organizational change needed, IT transformation will impact workflow, business rules, what gets automated and perhaps most importantly create a corporate culture in the small business environment to allow you to compete with your large corporate competition.";
  const description =
    "Revamp your business operations with our IT transformation services. Our expert team can advise and develop a customized IT transformation plan for your company. This can include changes to network architecture, hardware, software, and data management, all aimed at reducing costs and increasing efficiency.";
  <AllServicesCommonSection
    header={header}
    strong={strong}
    description={description}
  />;
  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="IT TRANSFORMATION"
        heading="IT TRANSFORMATION"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ItTransformation;
