import { createSlice } from "@reduxjs/toolkit";

export const userPostSlice = createSlice({
  name: "User Detail",
  initialState: {
    userPost: [],
    allPost: [],
  },
  reducers: {
    add: (state) => {
      state.value = "";
    },
    addUserPost: (state, action) => {
      state.userPost = [...state.userPost, action.payload];
    },
    addAllUserPost: (state, action) => {
      state.userPost = [...state.userPost, ...action.payload];
    },
    addAllPost: (state, action) => {
      state.allPost = action.payload;
    },
    reset: (state) => {
      state.userPost = [];
      state.allPost = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUserPost, addAllUserPost, reset, addAllPost } =
  userPostSlice.actions;

export const userPostSelector = (state) => state.userPost; //this is selector

export default userPostSlice.reducer;
