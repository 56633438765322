import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import { AccountingServicesData } from "../../constant/service";

const AccountingServices = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="ACCOUNTING SERVICES"
        heading="ACCOUNTING SERVICES"
      />
      <div className="webdesigndevelopment_section_4">
        <div className="webdesigndevelopment_section_4_heading">
          BUSINESS SERVICES
        </div>
        <div className="webdesigndevelopment_section_4_title">
          Accounting Services
        </div>
      </div>
      <div className="webdesigndevelopment_section_card_container">
        {AccountingServicesData.map((item, i) => {
          return (
            <div
              className="webdesigndevelopment_card_container"
              style={{ width: "40%" }}
              key={i}
            >
              <div className="webdesigndevelopment__card_icon">
                <img src={item.icon} alt="" srcSet="" />
              </div>
              <div className="section_3_card_title">{item.title}</div>
              <div className="webdesigndevelopment_card_description">
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default AccountingServices;
