import React, { useEffect, useState } from "react";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";
import styles from "./userPost.module.css";
import AddBlogOverlayContainer from "../../../components/OverlayContainer/AddBlogOverlayContainer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAllUserPost, reset } from "../../../Redux/Action/userPostAction";
import { getAllUserPostQuery } from "../../../utils/apiQuery";

const UserPost = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ourData = useSelector((state) => state.userPost.userPost);
  const [show, setShow] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const authToken = JSON.parse(localStorage.getItem("user"))?.token;

  const fetchData = async () => {
    const response = await getAllUserPostQuery(authToken);
    const res = await response.json();
    if (res.success === 0) {
      localStorage.clear();
      navigate("/admin/login");
    } else {
      if (isMounted) {
        dispatch(reset());
        dispatch(addAllUserPost(res.data));
      }
    }
  };

  useEffect(() => {
    setIsMounted(true);
    fetchData();
    return () => {
      // Cleanup function to be called when the component is unmounted or before running the effect again
      setIsMounted(false); // Set the flag to false to indicate the component is unmounted
    };
  }, []);

  const handleOnClick = () => {
    setShow((p) => !p);
  };

  return (
    <div>
      {/* <Navbar login={true} /> */}
      {show && <AddBlogOverlayContainer handleOnClick={handleOnClick} />}
      <div className={styles.box}>
        <div
          className={styles.card}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div
            style={{ fontSize: "18px", fontWeight: "600", cursor: "pointer" }}
            onClick={handleOnClick}
          >
            Add Blog
          </div>
        </div>
        {!!ourData &&
          ourData.map((item) => {
            const para = item.paragraph.replace(/<[^>]+>/g, "");
            return (
              <div
                key={item.id}
                className={styles.card}
                onClick={() => {
                  navigate(`/admin/post/${item.id}`);
                  localStorage.setItem(item.id, JSON.stringify(item));
                }}
              >
                <div className={styles.author}>
                  <img
                    src="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png"
                    alt=""
                    srcSet=""
                    style={{ width: "15%" }}
                  />
                  <div>{item.author}</div>
                </div>
                <div className={styles.time}>{item.time}</div>
                <div className={styles.heading}>{item.heading}</div>
                <div className={styles.para}>{para}</div>
                <div
                  className={styles.read_more}
                  // onClick={() => handleOnClick(item)}
                >
                  Read More
                </div>
              </div>
            );
          })}
      </div>

      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default UserPost;
