import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import ContactForm from "../../components/ContactForm";
import { contactData } from "../../constant/contactPage";

const ContactUs = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner currentPage="Contact Us" heading="Contact Us" />
      <div className="contact_section_3">
        <div className="contact_section_3_p_1">
          <div className="triangle"></div>
          Get In Touch
        </div>
        <div className="contact_section_3_p_2">Need A Quick Query</div>
        <div className="contact_section_3_p_3">
          Feel free to reach out to us with any questions you may have - we're
          always happy to help!
          <br /> We respond to all inquiries within 24 hours.
        </div>
      </div>
      <div className="contact_section_4_lower">
        {contactData.map((item, i) => {
          return (
            <div
              className="contact_card_container"
              onClick={(e) => {
                if (i === 0) {
                  window.location.href = "mailto:info@businessneedsinc.com";
                  e.preventDefault();
                }
              }}
            >
              <div className="contact_card_icon">
                {/* <img src={item.icon} alt="" srcSet="" /> */}
                {item.icon}
              </div>
              <div className="section_3_card_title">{item.title}</div>
              <div className="section_3_card_description">
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
      <div className="contact_section_5">
        <div className="section_5_left">
          <div className="section_5_left_map">
            <iframe
              title="add1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6241.61919636819!2d-117.80992201903358!3d34.03405686546181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c32bb3182437b7%3A0x622a53cf8b912e40!2sBusiness%20Needs%20Inc!5e0!3m2!1sen!2sin!4v1642615068156!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", border: "none" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="section_5_left_map">
            <iframe
              title="add2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.0779144026037!2d77.43444691403457!3d23.203830984861547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43b2122f0365%3A0x85ac10dc767e04d3!2s80%2C%20Railway%20Housing%20Society%2C%20E-7%2C%20Bharat%20Nagar%2C%20Bhopal%2C%20Madhya%20Pradesh%20462016!5e0!3m2!1sen!2sin!4v1642786453280!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", border: "none" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="section_5_right">
          <div className="contact_section_3 contact_form_heading_title">
            <div className="contact_section_3_p_1">
              <div className="triangle"></div>
              Contact Us
            </div>
            <div className="contact_section_3_p_2">Send us a Message</div>
            <div className="contact_section_3_p_3">
              If you are looking for a diverse, well rounded team that has the
              technology expertise contact Business Needs Inc. to request a free
              assessment of consultation of your IT Process.
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ContactUs;
