import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import styles from "./Search.module.css";

const Search = () => {
  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner currentPage="Search" heading="Search" />
      <div className={styles.search_section}></div>
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default Search;
