import React from "react";
import styles from "./blogComponent.module.css";
import { postImage } from "../../constant/postImage";

const BlogComponent = ({ singlePost }) => {
  const htmlString = singlePost?.paragraph;
  const theObj = { __html: htmlString };
  const MyComponent = () => {
    return (
      <div dangerouslySetInnerHTML={theObj} style={{ background: "white" }} />
    );
  };
  return (
    <div style={{ width: "100%", background: "white", lineHeight: "1.5" }}>
      <div className={styles.post_container}>
        <div className={styles.image_container}>
          {/* <img src={singlePost?.url} alt="" srcSet="" /> */}
          <img
            src={postImage[Math.floor(Math.random() * 8)]}
            alt=""
            srcSet=""
          />
        </div>
        <div className={styles.heading_detail}>
          <div>{singlePost?.author}</div>
          <div>{singlePost?.time}</div>
          {/* {singlePost.comments.length > 0 ? (
              <div> {singlePost.comments.length} Comment</div>
            ) : (
              <div> 0 Comment</div>
            )} */}
        </div>
        <MyComponent />
      </div>
    </div>
  );
};

export default BlogComponent;
