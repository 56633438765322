export const contactData = [
  {
    icon: <i className="fa-regular fa-envelope" style={{ color: "white" }}></i>,
    // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
    title: "Our Email Address",
    description: "info@businessneedsinc.com",
  },
  {
    icon: <i className="fa-solid fa-headphones" style={{ color: "white" }}></i>,
    // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
    title: "Contact Number",
    description: "(909) 869-1173",
  },
  {
    icon: (
      <i className="fa-solid fa-building-flag" style={{ color: "white" }}></i>
    ),
    // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
    title: "Headquarter",
    description:
      "USA: 1950 S Brea Canyon, Diamond Bar , CA‚ 91765, USA",
  },
  {
    icon: (
      <i className="fa-solid fa-building-flag" style={{ color: "white" }}></i>
    ),
    // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
    title: "Delivery center",
    description: "India: E7/80, ARERA COLONY, BHOPAL – 462039 (M.P)",
  },
];
