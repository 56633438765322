import "./App.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Routers from "./routes/Routers";

function App() {
  return (
    <div className="App">
      <div className="top_section">
        <div className="top_left_section">
          <div
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:info@businessneedsinc.com";
              e.preventDefault();
            }}
          >
            <i
              className="fa-regular fa-envelope"
              style={{ color: "#036ffb" }}
            ></i>
            &nbsp;&nbsp;info@businessneedsinc.com
          </div>
          <div>
            <i className="fa-solid fa-headset" style={{ color: "#036ffb" }}></i>
            &nbsp;&nbsp;(909) 869-1173
          </div>
        </div>
        <div className="top_right_section">
          <div>Welcome to Business Needs Inc. </div>
          <div className="top_right_section_icon_container">
            <div
              className="top_right_section_icon"
              onClick={() => {
                window.open("https://twitter.com/BusinessNeeds_", "_blank");
              }}
            >
              <i
                className="fa-brands fa-x-twitter"
                style={{ color: "#036ffb" }}
              ></i>
            </div>
            <div
              className="top_right_section_icon"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/business-needs-inc",
                  "_blank"
                );
              }}
            >
              <i
                className="fa-brands fa-linkedin-in"
                style={{ color: "#036ffb" }}
              ></i>
            </div>
            <div
              className="top_right_section_icon"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=sI1dTHf3Nq0",
                  "_blank"
                );
              }}
            >
              <i
                className="fa-brands fa-youtube"
                style={{ color: "#036ffb" }}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <Routers />
      <ScrollToTop />
    </div>
  );
}

export default App;
