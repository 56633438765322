import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchPagePopUp from "./SearchPagePopUp/SearchPagePopUp";
import Logo from "../assets/Business-Needs-Logo-Small.png";
import { Brand } from "../constant/brand";

const Navbar = () => {
  const login = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  // for onclick hamburger Service Dropdown class will change start
  const [hamburgerServiceDropdownOnClick, setHamburgerServiceDropdownOnClick] =
    useState("hamburger_service_dropdown_trail_1");
  const handleOnClickHamburgerService = () => {
    if (
      hamburgerServiceDropdownOnClick === "hamburger_service_dropdown_trail_1"
    ) {
      setHamburgerServiceDropdownOnClick(
        "hamburger_service_dropdown_trail_1 hamburger_service_dropdown_trail_1_change"
      );
    } else {
      setHamburgerServiceDropdownOnClick("hamburger_service_dropdown_trail_1");
    }
  };
  // for onclick hamburger Service Dropdown class will change end

  // for onclick on hamburger brand start
  const [hamburgerBrandDropdownOnClick, setHamburgerBrandDropdownOnClick] =
    useState("hamburger_brand_dropdown");
  const handleOnClickHamburgerBrand = () => {
    if (hamburgerBrandDropdownOnClick === "hamburger_brand_dropdown") {
      setHamburgerBrandDropdownOnClick(
        "hamburger_brand_dropdown hamburger_brand_dropdown_change"
      );
    } else {
      setHamburgerBrandDropdownOnClick("hamburger_brand_dropdown");
    }
  };
  //  for onclick on hamburger end

  const [classAdded, setClassAdded] = useState("navbar_container");
  const [
    navbarServiceDropDownOnScrollClass,
    setNavbarServiceDropDownOnScrollClass,
  ] = useState("navbar_dropdown");
  const [
    navbarProfileDropDownOnScrollClass,
    setNavbarProfileDropDownOnScrollClass,
  ] = useState("navbar_dropdown_profile");
  const [searchWrapperShow, setSearchWrapperShow] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 100) {
        setClassAdded("navbar_container navbar_sticky");
        if (login) {
          setNavbarServiceDropDownOnScrollClass(
            "navbar_dropdown navbar_dropdown_onScroll navbar_dropdown_on_login"
          );
        } else {
          setNavbarServiceDropDownOnScrollClass(
            "navbar_dropdown navbar_dropdown_onScroll"
          );
        }
        setNavbarProfileDropDownOnScrollClass(
          "navbar_dropdown_profile navbar_dropdown_profile_onScroll"
        );
        // setNavbarServiceDropDownOnScrollClass("navbar_dropdown");
      } else {
        setClassAdded("navbar_container");
        if (login) {
          setNavbarServiceDropDownOnScrollClass(
            "navbar_dropdown navbar_dropdown_on_login"
          );
        } else {
          setNavbarServiceDropDownOnScrollClass("navbar_dropdown");
        }
        setNavbarProfileDropDownOnScrollClass("navbar_dropdown_profile");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // if (searchWrapperShow) {
  //   return <SearchPagePopUp />;
  // }
  const condition =
    hamburgerServiceDropdownOnClick !== "hamburger_service_dropdown_trail_1";

  const brandCondition =
    hamburgerBrandDropdownOnClick !== "hamburger_brand_dropdown";
  return (
    <div className={classAdded}>
      {searchWrapperShow && (
        <SearchPagePopUp
          searchWrapperShow={searchWrapperShow}
          setSearchWrapperShow={setSearchWrapperShow}
        />
      )}
      <div className="navbar_left">
        <img
          // src="https://www.businessneedsinc.com/wp-content/uploads/2021/12/Business-Needs-Logo-Small.png"
          src={Logo}
          alt="Logo"
          srcSet=""
          style={{
            width: "100%",
            maxWidth: "220px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="navbar_right">
        <input type="checkbox" id="hamburger_menu_active" />
        <label htmlFor="hamburger_menu_active" className="hamburger_menu_btn">
          <span></span>
        </label>
        <label
          htmlFor="hamburger_menu_active"
          className="hamburger_close"
        ></label>
        {/* hamburger menu start */}
        <div
          className="hamburger_menu_wrapper"
          style={{
            height: condition && "600px",
          }}
        >
          <div className="hamburger_menu_wrapper_ul">
            <div>
              <Link className="navbar_right_link_style" to="/">
                Home
              </Link>
            </div>
            <div>
              <Link className="navbar_right_link_style" to="/about">
                About Us
              </Link>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  className="navbar_right_link_style navbar_services"
                  // to="/services"
                  // style={{ textAlign: "left" }}
                >
                  Our Brands
                </div>
                {brandCondition ? (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/25/25678.png"
                    alt=""
                    srcSet=""
                    width="10px"
                    height="10px"
                    style={{ filter: "invert(1)", marginLeft: "10%" }}
                    onClick={handleOnClickHamburgerBrand}
                  />
                ) : (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/25/25243.png"
                    alt=""
                    srcSet=""
                    width="10px"
                    height="10px"
                    style={{ filter: "invert(1)", marginLeft: "10%" }}
                    onClick={handleOnClickHamburgerBrand}
                  />
                )}
              </div>
              <div className={hamburgerBrandDropdownOnClick}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "30px",
                  }}
                >
                  {Object.values(Brand).map((item, i) => {
                    if (!item.link)
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "20px",
                            // border: "2px solid red",
                          }}
                        >
                          <img
                            style={{
                              transform: item.title === "HARSHU" && "scale(3)",
                              height: "50px",
                              aspectRatio: "1/1",
                              objectFit: "contain",
                            }}
                            src={
                              item.title === "RIYU"
                                ? `${process.env.PUBLIC_URL}${item.whiteImage}`
                                : `${process.env.PUBLIC_URL}${item.image}`
                            }
                            alt={item.title}
                          />
                          {!item.link && (
                            <div style={{ color: "white", fontSize: "12px" }}>
                              Coming Soon!!
                            </div>
                          )}
                        </div>
                      );
                    else
                      return (
                        <a
                          href={item.link}
                          rel="noopener"
                          target="_blank"
                          style={
                            {
                              // border: "2px solid red",
                            }
                          }
                        >
                          <img
                            style={{
                              transform:
                                item.title === "RNR Industries" && "scale(2)",
                              height: "50px",

                              aspectRatio: "1/1",
                              objectFit: "contain",
                            }}
                            alt={item.title}
                            src={
                              item.title === "RIYU"
                                ? `${process.env.PUBLIC_URL}${item.whiteImage}`
                                : `${process.env.PUBLIC_URL}${item.image}`
                            }
                          />
                        </a>
                      );
                  })}
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Link
                  className="navbar_right_link_style navbar_services"
                  to="/services"
                  // style={{ textAlign: "left" }}
                >
                  Services
                </Link>
                {condition ? (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/25/25678.png"
                    alt=""
                    srcSet=""
                    width="10px"
                    height="10px"
                    style={{ filter: "invert(1)", marginLeft: "10%" }}
                    onClick={handleOnClickHamburgerService}
                  />
                ) : (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/25/25243.png"
                    alt=""
                    srcSet=""
                    width="10px"
                    height="10px"
                    style={{ filter: "invert(1)", marginLeft: "10%" }}
                    onClick={handleOnClickHamburgerService}
                  />
                )}
              </div>
              <div className={hamburgerServiceDropdownOnClick}>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/ittransformation"
                  >
                    IT Transformation
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/cloudtransition"
                  >
                    Cloud Transition
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/webdesignanddevelopment"
                  >
                    Web Design & Development
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/softwaredevelopment"
                  >
                    Software Development
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/itprofessionalstaffing"
                  >
                    IT Managed Services
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/itprofessionalstaffing"
                  >
                    IT Professional Staffing
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/applicationsecurity"
                  >
                    Application Security
                  </Link>
                </div>
                <div>
                  <Link
                    className="navbar_right_link_style navbar_services"
                    to="/services/databasemanagement"
                  >
                    Database Management
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <Link className="navbar_right_link_style" to="/news">
                News
              </Link>
            </div>
            <div>
              <Link className="navbar_right_link_style" to="/career">
                Career
              </Link>
            </div>
            <div>
              <Link className="navbar_right_link_style" to="/contactus">
                Contact Us
              </Link>
            </div>
            {!!login && (
              <div
                className="navbar_right_link_style"
                onClick={() => {
                  localStorage.clear();
                  navigate("/admin/login");
                  return;
                }}
              >
                Logout
              </div>
            )}
          </div>
        </div>
        {/* hamburger menu end*/}
        <div className="navbar_right_link">
          <Link className="navbar_right_link_style" to="/">
            Home
          </Link>
          <Link className="navbar_right_link_style" to="/about">
            About Us
          </Link>
          <div
            className="navbar_right_link_style navbar_services"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            to="/services"
          >
            Our Brands
            <img
              src="https://cdn-icons-png.flaticon.com/512/25/25243.png"
              alt=""
              srcSet=""
              width="10px"
              height="10px"
              style={{ marginLeft: "7px" }}
              onClick={handleOnClickHamburgerService}
            />
            <div
              className={navbarServiceDropDownOnScrollClass}
              style={
                window.scrollY < 100
                  ? {
                      height: "10px",
                      border: "none",
                      top: "90px",
                      backgroundColor: "transparent",
                    }
                  : {
                      height: "10px",
                      top: "50px",
                      backgroundColor: "transparent",
                      border: "none",
                    }
              }
            ></div>
            <div className={navbarServiceDropDownOnScrollClass}>
              {/* <Link
                className="navbar_dropdown_section navbar_right_link_style "
                to="/services/ittransformation"
              >
                IT Transformation
              </Link> */}
              {Object.values(Brand).map((item, i) => {
                if (!item.link)
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                        // border: "2px solid red",
                      }}
                    >
                      <img
                        style={{
                          transform:
                            item.title === "RNR Industries"
                              ? "scale(2)"
                              : item.title === "HARSHU" && "scale(1.5)",
                          height: "50px",
                          aspectRatio: "1/1",
                          objectFit: "contain",
                        }}
                        src={
                          item.title === "RIYU"
                            ? `${process.env.PUBLIC_URL}${item.whiteImage}`
                            : `${process.env.PUBLIC_URL}${item.image}`
                        }
                        alt={item.title}
                      />
                      {!item.link && (
                        <div style={{ color: "white", fontSize: "12px" }}>
                          Coming Soon!!
                        </div>
                      )}
                    </div>
                  );
                else
                  return (
                    <a
                      href={item.link}
                      rel="noopener"
                      target="_blank"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px",
                        // border: "2px solid red",
                      }}
                    >
                      <img
                        style={{
                          transform:
                            item.title === "RNR Industries"
                              ? "scale(2)"
                              : item.title === "HARSHU" && "scale(1.5)",
                          height: "50px",
                          aspectRatio: "1/1",
                          objectFit: "contain",
                        }}
                        alt={item.title}
                        src={
                          item.title === "RIYU"
                            ? `${process.env.PUBLIC_URL}${item.whiteImage}`
                            : `${process.env.PUBLIC_URL}${item.image}`
                        }
                      />
                    </a>
                  );
              })}
            </div>
          </div>
          <Link
            className="navbar_right_link_style navbar_services"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            to="/services"
          >
            Services
            <img
              src="https://cdn-icons-png.flaticon.com/512/25/25243.png"
              alt=""
              srcSet=""
              width="10px"
              height="10px"
              style={{ marginLeft: "10%" }}
              onClick={handleOnClickHamburgerService}
            />
            <div
              className={navbarServiceDropDownOnScrollClass}
              style={
                window.scrollY < 100
                  ? {
                      height: "10px",
                      border: "none",
                      top: "90px",
                      backgroundColor: "transparent",
                    }
                  : {
                      height: "10px",
                      top: "50px",
                      backgroundColor: "transparent",
                      border: "none",
                    }
              }
            ></div>
            <div className={navbarServiceDropDownOnScrollClass}>
              {/* <div className="navbar_dropdown"> */}
              <Link
                className="navbar_dropdown_section navbar_right_link_style "
                to="/services/ittransformation"
              >
                IT Transformation
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/cloudtransition"
              >
                Cloud Transition
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/webdesignanddevelopment"
              >
                Web Design & Development
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/softwaredevelopment"
              >
                Software Development
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/itprofessionalstaffing"
              >
                IT Professional Staffing
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/itprofessionalstaffing"
              >
                IT Managed Services
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/applicationsecurity"
              >
                Application Security
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/databasemanagement"
              >
                Database Management
              </Link>
              {/* <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/customisedfinancialproduct"
              >
                Customised Financial Product{" "}
              </Link>
              <Link
                className="navbar_dropdown_section navbar_right_link_style"
                to="/services/accountingservices"
              >
                Accounting Services{" "}
              </Link> */}
            </div>
          </Link>
          <Link className="navbar_right_link_style" to="/news">
            News
          </Link>
          <Link className="navbar_right_link_style" to="/career">
            Career
          </Link>
          <Link className="navbar_right_link_style" to="/contactus">
            Contact Us
          </Link>
          {!!login && (
            <div
              className="navbar_right_link_style navbar_admin"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              Profiles
              <img
                src="https://cdn-icons-png.flaticon.com/512/25/25243.png"
                alt=""
                srcSet=""
                width="10px"
                height="10px"
                style={{ marginLeft: "10%" }}
                onClick={handleOnClickHamburgerService}
              />
              <div
                className={navbarProfileDropDownOnScrollClass}
                style={
                  window.scrollY < 100
                    ? {
                        height: "20px",
                        border: "none",
                        top: "90px",
                        backgroundColor: "transparent",
                      }
                    : {
                        height: "20px",
                        top: "50px",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                }
              ></div>
              <div className={navbarProfileDropDownOnScrollClass}>
                <Link
                  className="navbar_dropdown_section navbar_right_link_style "
                  to="/admin"
                >
                  Dashboard
                </Link>
                <Link
                  className="navbar_dropdown_section navbar_right_link_style "
                  to="/admin/post"
                >
                  Post
                </Link>
                <div
                  className="navbar_dropdown_section navbar_right_link_style"
                  onClick={() => {
                    localStorage.clear();
                    navigate("/admin/login");
                    return;
                  }}
                >
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="navbar_search">
          <img
            src="https://cdn-icons-png.flaticon.com/512/149/149852.png"
            alt=""
            srcSet=""
            width={15}
            // onClick={() => navigate("/search")}
            onClick={() => setSearchWrapperShow(true)}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
