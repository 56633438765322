import React, { useState } from "react";
import styles from "./UserProfileEditOverlayContainer.module.css";
import { useNavigate } from "react-router-dom";
import { updateUserDetailQuery } from "../../utils/apiQuery";

const UserProfileEditOverlay = ({ handleOnCloseOverlay }) => {
  const navigate = useNavigate();
  const authToken = JSON.parse(localStorage.getItem("user"))?.token;
  const userDetail = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    email: userDetail.email,
    number: userDetail.number,
  });

  const { firstName, lastName, email, number } = formData;

  const fetchData = async () => {
    const response = await updateUserDetailQuery(formData, authToken);
    const data = await response.json();
    if (data.success === 1) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...formData,
          token: userDetail.token,
        })
      );
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
      });
      navigate("/admin");
      handleOnCloseOverlay();
    } else {
      alert("please try after sometime");
      navigate("/admin");
      handleOnCloseOverlay();
    }
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className={styles.contact_overlay_div_container}
        style={{ height: window.innerHeight }}
      ></div>
      <div className={styles.contact_overlay_form_div}>
        {/* <button>x</button> */}
        <img
          src="https://cdn-icons-png.flaticon.com/512/2874/2874787.png"
          alt=""
          srcSet=""
          onClick={handleOnCloseOverlay}
          className={styles.contact_overlay_cross_button}
        />
        <form className={styles.form} onSubmit={handleOnSubmit}>
          <div className={styles.title}>Edit</div>
          <div className={styles.subtitle}>Your Profile Detail!</div>
          {/* <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="name"
              className={styles.input}
              type="name"
              placeholder=" "
              value={name}
              name="name"
              onChange={handleOnChange}
              autoComplete="off"
            />
            <div className={styles.cut}></div>
            <label htmlFor="name" className={styles.placeholder}>
              Name
            </label>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className={`${styles.input_container} ${styles.ic1}`}
              style={{ width: "45%" }}
            >
              <input
                id="firstName"
                className={styles.input}
                type="text"
                placeholder=" "
                name="firstName"
                value={firstName}
                onChange={handleOnChange}
              />
              <div className={styles.cut}></div>
              <label htmlFor="firstName" className={styles.placeholder}>
                First name
              </label>
            </div>
            <div
              className={`${styles.input_container} ${styles.ic1}`}
              style={{ width: "45%" }}
            >
              <input
                id="lastName"
                className={styles.input}
                type="text"
                placeholder=" "
                value={lastName}
                name="lastName"
                onChange={handleOnChange}
              />
              <div className={styles.cut}></div>
              <label htmlFor="lastName" className={styles.placeholder}>
                Last name
              </label>
            </div>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="email"
              className={styles.input}
              type="email"
              value={email}
              placeholder=" "
              name="email"
              onChange={handleOnChange}
            />
            <div className={styles.cut}></div>
            <label htmlFor="email" className={styles.placeholder}>
              Email
            </label>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="number"
              className={styles.input}
              type="text"
              placeholder=" "
              value={number}
              name="number"
              onChange={handleOnChange}
              autoComplete="off"
            />
            <div className={styles.cut}></div>
            <label htmlFor="number" className={styles.placeholder}>
              Contact No.
            </label>
          </div>
          <input className={styles.submit} type="submit" value="Submit" />
        </form>
      </div>
    </>
  );
};

export default UserProfileEditOverlay;
