import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import { WebDesignAndDevelopmentData } from "../../constant/service";

const WebDesignAndDevelopment = () => {
  const header = "Elevate Your Business with a Professional Website";
  const strong = "";
  const description =
    "At Business Needs Inc., we offer professional website design and management services that can help elevate your online presence and attract more customers. Our team will work with you to create a website that not only looks great, but also functions seamlessly and is optimized for search engines. With our website design and management services, you can enjoy the benefits of having a professional-looking website without the hassle of maintaining it yourself. We offer support to ensure that your website stays up to date and fully functional at all times.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="WEB DESIGN & DEVELOPMENT"
        heading="WEB DESIGN & DEVELOPMENT"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div className="webdesigndevelopment_section_4">
        <div
          className="webdesigndevelopment_section_4_heading"
          style={{ justifyContent: "center" }}
        >
          BUSINESS SERVICES
        </div>
        <div className="webdesigndevelopment_section_4_title">
          WE Will Create and Manage Your Companies
        </div>
      </div>
      <div className="webdesigndevelopment_section_card_container">
        {WebDesignAndDevelopmentData.map((item, i) => {
          return (
            <div className="webdesigndevelopment_card_container" key={i}>
              <div className="webdesigndevelopment__card_icon">
                <img src={item.icon} alt="" srcSet="" />
              </div>
              <div className="section_3_card_title">{item.title}</div>
              <div className="webdesigndevelopment_card_description">
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default WebDesignAndDevelopment;
