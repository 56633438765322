import React from "react";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";

const Error = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div
        style={{
          height: "300px",
          background: "white",
          textAlign: "center",
          margin: "auto",
        }}
      >
        Please Check the URL
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Error;
