import { configureStore } from "@reduxjs/toolkit";

import userDetailReducer from "./Action/userDetailAction";
import userPostReducer from "./Action/userPostAction";

export default configureStore({
  reducer: {
    userDetail: userDetailReducer,
    userPost: userPostReducer,
  },
});
