import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postImage } from "../../constant/postImage";
import { getAllPostQuery } from "../../utils/apiQuery";
import { addAllPost } from "../../Redux/Action/userPostAction";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import styles from "./news.module.css";
import SpinLoader from "../../components/Loader/SpinLoader";

const News = () => {
  const navigate = useNavigate();
  const ourData = useSelector((state) => state.userPost.allPost);

  const dispatch = useDispatch(); // add dispatch user from store to import here
  const [loading, setLoading] = useState(true);
  // const [show, setShow] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [searchData, setSearchData] = useState([]);

  const fetchData = async () => {
    const response = await getAllPostQuery();
    const res = await response.json();
    if (res.success === 1) {
      if (isMounted) {
        setLoading(false);
        dispatch(addAllPost(res.data));
        setSearchData(res.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setIsMounted(true);
    fetchData();
    return () => {
      // Cleanup function to be called when the component is unmounted or before running the effect again
      setIsMounted(false); // Set the flag to false to indicate the component is unmounted
    };
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const newData = ourData.filter((item) =>
        item.heading.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSearchData(newData);
    } else {
      setSearchData(ourData);
    }
  };
  const handleOnClick = (item) => {
    localStorage.setItem(item.id, JSON.stringify(item));
    navigate(`/news/${item.id}`);
  };
  // console.log("loader", loading, !loading, !!loading);
  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner currentPage="News" heading="News" />
      <div className={styles.blog_container}>
        <div className={styles.blog_container_left}>
          {loading ? (
            <SpinLoader />
          ) : (
            ourData.map((item, i) => {
              const para = item.paragraph.replace(/<[^>]+>/g, "");
              return (
                // <div key={i} style={{ border: "2px solid blue" }}>
                // </div>
                <div className={styles.card_detail}>
                  <img
                    className={styles.image}
                    src={postImage[i % postImage.length]}
                    alt="promotion img"
                  />
                  <div className={styles.author}>
                    <img
                      src="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png"
                      alt=""
                      srcSet=""
                      style={{ width: "3%" }}
                    />
                    <div>{item.author}</div>
                    <div className={styles.time}>{item.time}</div>
                  </div>
                  <div className={styles.heading}>{item.heading}</div>
                  <div className={styles.para}>{para}</div>
                  <div
                    className={styles.read_more}
                    onClick={() => handleOnClick(item)}
                  >
                    Read More
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className={styles.blog_container_right}>
          <input
            className={styles.search_input}
            placeholder="Search News & Events"
            type="search"
            name="search"
            autoFocus
            onChange={handleChange}
          />
          <div className={styles.container}>
            {searchData.length > 0 ? (
              searchData.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={styles.card}
                    onClick={() => handleOnClick(item)}
                  >
                    <p>{item.heading}</p>
                  </div>
                );
              })
            ) : (
              <div>Blog Is Not Available!!</div>
            )}
          </div>
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default News;
