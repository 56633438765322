import { createSlice } from "@reduxjs/toolkit";

export const userDetailSlice = createSlice({
  name: "User Detail",
  initialState: {
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    number: "",
  },
  reducers: {
    add: (state) => {
      state.value = "";
    },
    addUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.number = action.payload.number;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUser } = userDetailSlice.actions;

export const userDetailSelector = (state) => state.userDetail; //this is selector

export default userDetailSlice.reducer;
