import React, { useRef } from "react";
import styles from "./blogCarousel.module.css";
import { useNavigate } from "react-router-dom";
import { postImage } from "../../constant/postImage";

const ContactUsCarousel = ({ data }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const refCard = useRef(null);

  const handleOnClickRight = () => {
    ref.current.scrollLeft += refCard.current.offsetWidth;
  };

  const handleOnClickLeft = () => {
    ref.current.scrollLeft -= refCard.current.offsetWidth;
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.smallbox}>
          <button className={styles.button} onClick={handleOnClickLeft}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              style={{ width: "20px" }}
              alt=""
              srcSet=""
            />
          </button>
        </div>
        <div className={styles.contact_us_box} ref={ref}>
          {data.map((item, i) => {
            // const para = item.paragraph.replace(/<[^>]+>/g, "");
            return (
              <div
                className={styles.contact_us_card}
                ref={refCard}
                key={i}
                onClick={() => {
                  localStorage.setItem("client", JSON.stringify(item));
                  navigate("/admin/client");
                }}
              >
                {/* <img
                  className={styles.image}
                  src={postImage[i % postImage.length]}
                  alt="promotion img"
                /> */}
                <div className={styles.contact_us_card_detail}>
                  <div className={styles.author}>
                    <img
                      src="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png"
                      alt=""
                      srcSet=""
                      style={{ width: "15%" }}
                    />
                    <div>{item.services}</div>
                  </div>
                  <div className={styles.time}>{item.email}</div>
                  <div className={styles.time}>{item.contact}</div>
                  <div className={styles.heading}>{item.name}</div>
                  <div className={styles.para}>{item.message}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.smallbox}>
          <button className={styles.button} onClick={handleOnClickRight}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
              style={{ width: "20px" }}
              alt=""
              srcSet=""
            />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ContactUsCarousel;
