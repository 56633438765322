import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";

const SoftwareDevelopment = () => {
  const header = "Maximize Your Business Potential with Proprietary Software";
  const strong = "";
  const description =
    "At Business Needs Inc., we understand that your business has unique needs that require tailored solutions. That's why we offer proprietary software development services that are customized to your specific requirements. Our team will work with you to create software that will streamline your operations, increase efficiency, and optimize your revenue potential. With our proprietary software solutions, you can gain a competitive advantage while benefiting from personalized support and ongoing maintenance.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="SOFTWARE DEVELOPMENT"
        heading="SOFTWARE DEVELOPMENT"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default SoftwareDevelopment;
