export const client = [
  "/images/client/abbtech.png",
  "/images/client/avanit.jpg",
  "/images/client/collaboratesolution.png",
  "/images/client/datafix.png",
  "/images/client/digirati.png",
  "/images/client/digitaldhara.png",
  "/images/client/infintesolution.png",
  "/images/client/mutexsystem.png",
  "/images/client/nextgen.png",
  "/images/client/pallcorporation.jpg",
  "/images/client/parallon.png",
  "/images/client/skyscraperper.png",
  "/images/client/smallboard.png",
  "/images/client/useready.png",
  "/images/client/vigna.png",
  "/images/client/cepheid.png",
  // "/images/client/thinkAI.png",
];
