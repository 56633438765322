import React from "react";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";
import styles from "./login.module.css";

const SignUp = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div className={styles.form_container}>
        <div className={styles.form}>
          <div className={styles.title}>Welcome</div>
          <div className={styles.subtitle}>Let's create your account!</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className={`${styles.input_container} ${styles.ic1}`}
              style={{ width: "45%" }}
            >
              <input
                id="firstname"
                className={styles.input}
                type="text"
                placeholder=" "
              />
              <div className={styles.cut}></div>
              <label htmlFor="firstname" className={styles.placeholder}>
                First name
              </label>
            </div>
            <div
              className={`${styles.input_container} ${styles.ic1}`}
              style={{ width: "45%" }}
            >
              <input
                id="lastname"
                className={styles.input}
                type="text"
                placeholder=" "
              />
              <div className={styles.cut}></div>
              <label htmlFor="lastname" className={styles.placeholder}>
                Last name
              </label>
            </div>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="email"
              className={styles.input}
              type="email"
              placeholder=" "
            />
            <div className={styles.cut}></div>
            <label htmlFor="email" className={styles.placeholder}>
              Email
            </label>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="contact"
              className={styles.input}
              type="text"
              placeholder=" "
            />
            <div className={styles.cut}></div>
            <label htmlFor="contact" className={styles.placeholder}>
              Number
            </label>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="password"
              className={styles.input}
              type="password"
              placeholder=" "
            />
            <div className={styles.cut}></div>
            <label htmlFor="password" className={styles.placeholder}>
              Password
            </label>
          </div>
          <div className={`${styles.input_container} ${styles.ic1}`}>
            <input
              id="confirm_password"
              className={styles.input}
              type="password"
              placeholder=" "
            />
            <div className={styles.cut} style={{ width: "120px" }}></div>
            <label htmlFor="confirm_password" className={styles.placeholder}>
              Confirm Password
            </label>
          </div>
          <button type="text" className={styles.submit}>
            submit
          </button>
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default SignUp;
