import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";

const CustomisedFinancialSupport = () => {
  const contactData = [
    {
      icon: (
        <i
          className="fa-regular fa-file-lines"
          style={{ color: "#ffffff" }}
        ></i>
      ),
      // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
      title: "Invoicing",
      description: "",
    },
    {
      icon: <i className="fa-solid fa-receipt" style={{ color: "white" }}></i>,
      // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
      title: "Book Keeping",
      description: "",
    },
    {
      icon: (
        <i
          className="fa-regular fa-calendar-days"
          style={{ color: "white" }}
        ></i>
      ),
      // icon: "https://cdn-icons-png.flaticon.com/512/10930/10930239.png",
      title: "Timesheets",
      description: "",
    },
  ];

  const header = "Customised Financial Product (Cloud Based)";
  const strong = "";
  const description = "";
  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="CUSTOMISED FINANCIAL PRODUCT"
        heading="CUSTOMISED FINANCIAL PRODUCT"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div className="contact_section_4_lower">
        {contactData.map((item) => {
          return (
            <div className="contact_card_container">
              <div className="contact_card_icon">
                {/* <img src={item.icon} alt="" srcSet="" /> */}
                {item.icon}
              </div>
              <div className="section_3_card_title">{item.title}</div>
              {item.description !== "" && (
                <div className="section_3_card_description">
                  {item.description}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default CustomisedFinancialSupport;
