import React from "react";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import CommonBanner from "../../components/CommonBanner";
import about_01 from "../../assets/about_01.png";

const About = () => {
  const aboutImage = "/images/about_img_3.jpg";
  return (
    <div style={{ width: "100%" }}>
      {/* <Navbar /> */}
      <CommonBanner currentPage="About Us" heading="About Us" />
      <div className="about_section_3">
        <div className="about_section_3_about_banner_container">
          <img
            src={about_01}
            alt=""
            srcSet=""
            style={{ objectFit: "cover", width: "100%" }}
          />
        </div>
        <div className="about_section_3_about_text_container">
          <div className="about_section_3_p_1">
            <div className="triangle"></div>
            ABOUT BUSINESS NEEDS INC.
          </div>
          <div className="about_section_3_p_2">A Team You Can Rely On</div>
          <div className="about_section_3_p_3">
            At Business Needs Inc., we are more than just an IT solutions
            provider - we are your dedicated partner in driving your business
            forward. Since 2004, our expert team has been revolutionizing
            businesses with our personalized approach to technology solutions.
            We understand the unique challenges that businesses face in today's
            rapidly evolving digital landscape, and we work tirelessly to
            provide customized, end-to-end solutions that meet your specific
            needs. From talent solutions to professional staffing and everything
            in between, we are committed to delivering high-quality and reliable
            services that help your business run smoothly and efficiently.
            Contact us today to see how we can help elevate your business with
            expert IT solutions.
          </div>
        </div>
      </div>
      <div
        className="about_section_4_banner"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}${aboutImage})`,
        }}
      >
        <p className="about_section_4_banner_p_1">
          <div className="triangle"></div>
          More About Us
        </p>
        <p className="about_section_4_banner_p_2">
          Our knowledgeable team possesses unique and valuable skills to create
          end-to-end solutions tailored to your business needs. We partner the
          right team member to understand your complex business challenges,
          incorporate the necessary technology and processes, and provide
          high-quality delivery. Contact Business Needs Inc. for a free
          consultation and elevate your business with expert solutions.
        </p>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default About;
