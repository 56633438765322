import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import footerLogo from "../assets/Business-Needs-Logo-footer.png";
import PrivatePolicyOverlay from "./OverlayContainer/PrivatePolicyOverlay";
import TermAndCondition from "./OverlayContainer/TermAndCondition";

const Footer = () => {
  const navigate = useNavigate();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermAndCondition, setShowTermAndCondition] = useState(false);

  const handleOnClick = (option) => {
    if (option === "a") {
      console.log(option);
      setShowPrivacyPolicy((s) => !s);
    } else {
      console.log(option);
      setShowTermAndCondition((s) => !s);
    }
  };

  return (
    <div className="footer_container">
      {showPrivacyPolicy && (
        <PrivatePolicyOverlay handleOnClick={handleOnClick} />
      )}
      {showTermAndCondition && (
        <TermAndCondition handleOnClick={handleOnClick} />
      )}
      <div className="footer_upper">
        <div className="footer_upper_left">
          <img
            src={footerLogo}
            alt="Logo"
            srcSet=""
            className="footer_upper_left_logo"
          />
          <p className="footer_upper_left_description">
            Business Needs Inc. offers tailored IT solutions and network
            services to companies worldwide. We understand that each client is
            unique, and we work to provide customized solutions that meet your
            specific needs.
          </p>
          <div
            style={{
              // border: "2px solid blue ",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
              // padding: "20%",
              // borderRadius: "50px",
            }}
          >
            <div
              className="footer_upper_left_icon_container"
              onClick={() => {
                window.open("https://twitter.com/BusinessNeeds_", "_blank");
              }}
            >
              <i
                className="fa-brands fa-x-twitter"
                style={{ color: "#036ffb", width: "50%" }}
              ></i>
            </div>
            <div
              className="footer_upper_left_icon_container"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/business-needs-inc",
                  "_blank"
                );
              }}
            >
              <i
                className="fa-brands fa-linkedin-in"
                style={{ color: "#036ffb", width: "50%" }}
              ></i>
            </div>
            <div
              className="footer_upper_left_icon_container"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=sI1dTHf3Nq0",
                  "_blank"
                );
              }}
            >
              <i
                className="fa-brands fa-youtube"
                style={{ color: "#036ffb", width: "50%" }}
              ></i>
            </div>
          </div>
        </div>
        <div className="footer_upper_mid">
          <div className="footer_upper_right_title">Services</div>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style"
            to="/"
          >
            Home
          </Link>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style"
            to="/about"
          >
            About Us
          </Link>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style navbar_services"
            to="/services"
          >
            Services
          </Link>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style"
            to="/news"
          >
            News
          </Link>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style"
            to="/career"
          >
            Career
          </Link>
          <Link
            style={{ color: "#a7acb7", cursor: "pointer" }}
            className="navbar_right_link_style"
            to="/contactus"
          >
            Contact Us
          </Link>
        </div>
        <div className="footer_upper_right">
          <div className="footer_upper_right_title">Contact Information</div>
          <div className="footer_upper_right_description">
            <i
              className="fa-solid fa-building-flag"
              style={{ color: "white" }}
            ></i>
            {"    "}
            Business Needs Inc. 1950 S Brea Canyon, Diamond Bar , CA‚ 91765, USA
          </div>
          <div
            className="footer_upper_right_description"
            onClick={(e) => {
              window.location.href = "mailto:info@businessneedsinc.com";
              e.preventDefault();
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="fa-regular fa-envelope"
              style={{ color: "white" }}
            ></i>
            {"    "}info@businessneedsinc.com
          </div>
          <div className="footer_upper_right_description">
            <i
              className="fa-solid fa-headphones"
              style={{ color: "white" }}
            ></i>
            {"    "}(909) 869-1173
          </div>
        </div>
      </div>
      <div className="footer_lower">
        <div className="footer_lower_left">Copyright © 2022</div>
        <div className="footer_lower_right">
          <p
            className="footer_lower_right_p"
            // onClick={() => handleOnClick("a")}
            onClick={() => navigate("/privacypolicy")}
          >
            Privacy Policy
          </p>
          <p
            className="footer_lower_right_p"
            // onClick={() => handleOnClick("b")}
            onClick={() => navigate("/terms")}
          >
            Term & Condition
          </p>
          <Link to="/contactus" className="footer_lower_right_p">
            Support
          </Link>
          <Link to="/contactus" className="footer_lower_right_p">
            Help
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
