import React, { useRef } from "react";
import styles from "./clientCarousel.module.css";

const ClientCarousel = ({ client }) => {
  const ref = useRef(null);
  const refCard = useRef(null);

  const handleOnClickRight = () => {
    ref.current.scrollLeft += 2 * refCard.current.offsetWidth;
  };

  const handleOnClickLeft = () => {
    ref.current.scrollLeft -= 2 * refCard.current.offsetWidth;
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.smallbox}>
          <button className={styles.button} onClick={handleOnClickLeft}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              style={{ width: "20px" }}
              alt=""
              srcSet=""
            />
          </button>
        </div>
        <div className={styles.box} ref={ref}>
          {client.map((item, i) => {
            return (
              <div className={styles.card} ref={refCard} key={i}>
                <img
                  className={styles.image}
                  src={`${process.env.PUBLIC_URL}${client[i]}`}
                  alt="promotion img"
                />
              </div>
            );
          })}
        </div>
        <div className={styles.smallbox}>
          <button className={styles.button} onClick={handleOnClickRight}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
              style={{ width: "20px" }}
              alt=""
              srcSet=""
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientCarousel;
