import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import { ApplicationSecurityData } from "../../constant/service";

const ApplicationSecurity = () => {
  const header =
    "Secure Your Business with Our Expert Application Security Services";
  const strong = "";
  const description =
    "We understand the importance of keeping your business secure and protected from potential threats. That's why we offer expert application security services that can identify vulnerabilities and implement effective solutions to ensure your systems are protected. With our on-demand and continuous testing, as well as our advanced API and workflow integrations, you can rest assured that your business is secure and your data is protected. Our team of security specialists will work with you to create a customized program that fits your needs, so you can focus on running your business with confidence.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="APPLICATION SECURITY"
        heading="APPLICATION SECURITY"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div
        style={{
          padding: "0 4%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {ApplicationSecurityData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ApplicationSecurity;
