import React from "react";
import { services } from "../constant/service";
import { useNavigate } from "react-router-dom";

const ServiceSection = () => {
  const navigate = useNavigate();
  return (
    <div className="section_3_container">
      <div className="section_3_upper">
        <div className="section_3_upper_left">
          <p className="section_2_p_1">
            <div className="triangle"></div>OUR SERVICES
          </p>
          <p className="section_2_p_2">Excellent Services for Your Business</p>
          <div className="section_3_upper_left_hr"></div>
        </div>
        <hr className="section_3_hr" />
        <div className="section_3_upper_right">
          <p className="section_2_p_3">
            At Business Needs Inc. we specialize in Information Technology
            services and Talent solutions for companies globally. We believe
            that each client is unique, and there is no such thing as
            one-size-fits all in the world of technology.
          </p>
        </div>
      </div>
      <div className="section_3_lower">
        {services.map((item, i) => {
          return (
            <div className="flip_card" key={i}>
              <div className="flip_card_inner">
                <div className="flip_card_front">
                  <div className="section_3_card_icon">
                    <img
                      src={item.icon}
                      alt=""
                      srcSet=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="section_3_card_title">{item.title}</div>
                  <div
                    className="section_3_card_description"
                    style={{ width: "90%" }}
                  >
                    {item.description}
                  </div>
                </div>
                <div className="flip_card_back">
                  <button
                    className="flip_card_back_button"
                    type="button"
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceSection;
