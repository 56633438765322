import React from "react";
import { useNavigate } from "react-router-dom";

const CommonBanner = ({ currentPage, heading }) => {
  const navigate = useNavigate();
  const images = "/images/Us_Banner_2.jpg";
  return (
    <div
      className="common_banner_container"
      style={{
        backgroundImage: `linear-gradient(rgba(6, 14, 35, 0.5), rgba(6, 14, 35, 0.5)), url(${process.env.PUBLIC_URL}${images})`,
      }}
    >
      <div className="common_banner_container_title">{heading}</div>
      <div className="common_banner_container_div">
        <div
          className="common_banner_para navbar_right_link_style common_banner_container_div_link"
          onClick={() => navigate("/")}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/25/25694.png"
            alt=""
            width="15px"
            height="15px"
            style={{ filter: "invert(1)" }}
          />
          Home
        </div>
        <p className="common_banner_para common_banner_container_div_p">
          {">"} {currentPage}
        </p>
      </div>
    </div>
  );
};

export default CommonBanner;
