const authToken = JSON.parse(localStorage.getItem("user"))?.token;
// const host = "http://localhost:9000";
const host = "https://bni-backend.onrender.com";

export const getAllPostQuery = () => {
  const url = `${host}/api/post/getAllPost`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getAllUserPostQuery = (token) => {
  const url = `${host}/api/post`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
};

export const updateUserDetailQuery = (formData, token) => {
  const URL = `${host}/api/users`;

  return fetch(URL, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(formData),
  });
};

export const loginQuery = (formData) => {
  const URL = `${host}/api/users/login`;
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};

export const forgetPasswordQuery = (formData) => {
  const URL = `${host}/api/users/forgetpassword`;
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};

export const updatePostQuery = (newPost, token) => {
  const URL = `${host}/api/post`;

  return fetch(URL, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(newPost),
  });
};

export const deletePostQuery = (singlePost, token) => {
  const URL = `${host}/api/post`;

  return fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(singlePost),
  });
};

export const addNewPostQuery = (newPost, token) => {
  const URL = `${host}/api/post/create`;
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(newPost),
  });
};

export const addNewContactUsDetail = (newPost) => {
  const URL = `${host}/api/contactus/create`;

  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPost),
  });
};

export const getNewContactUsDetail = (token) => {
  const URL = `${host}/api/contactus/get`;

  return fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
};
