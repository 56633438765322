import React, { useState } from "react";
import PreFooterSection from "../../../components/PreFooterSection";
import Footer from "../../../components/Footer";
import styles from "./login.module.css";
import SpinLoader from "../../../components/Loader/SpinLoader";
import { useNavigate } from "react-router-dom";
import { forgetPasswordQuery } from "../../../utils/apiQuery";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const { email, password } = formData;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const fetchLogin = async () => {
      try {
        const response = await forgetPasswordQuery(formData);
        const data = await response.json();
        // dispatch(addUser(data));
        setLoading(false);
        if (data.success === 1) {
          alert("password is send to your email");
          navigate("/admin");
        } else {
          alert("wrong credentials");
        }
      } catch (error) {
        alert("Try Again Later!!");
      }
    };
    fetchLogin();
  };
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className={styles.form_container} style={{ height: "auto" }}>
        {!!loading ? (
          <SpinLoader />
        ) : (
          <form
            className={styles.form}
            style={{ marginRight: "auto" }}
            onSubmit={handleOnSubmit}
          >
            <div className={styles.subtitle}>Write Your Email!</div>
            <div className={`${styles.input_container} ${styles.ic1}`}>
              <input
                id="email"
                className={styles.input}
                type="email"
                value={email}
                placeholder=" "
                name="email"
                onChange={handleOnChange}
              />
              <div className={styles.cut}></div>
              <label htmlFor="email" className={styles.placeholder}>
                Email
              </label>
            </div>

            <input className={styles.submit} type="submit" value="Submit" />
          </form>
        )}
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default ForgetPassword;
