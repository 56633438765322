import React from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";

const CloudTransition = () => {
  const header = "Upgrade Your Business with Private Cloud Computing";
  const strong = "";
  const description =
    "At Business Needs Inc., we offer private cloud computing services that prioritize privacy, security, and data management. Our cost-effective solution dedicates exclusive hardware to each user, ensuring that your data is stored securely. With the private cloud, you can enjoy the benefits of pooled computing resources and virtualization while maintaining the security and reliability of a dedicated server. Our expert team can guide you through the transition to private cloud computing, making it an affordable process and enabling you to fully experience the benefits of enhanced privacy, security, and data management.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner currentPage="CLOUD TRANSITION" heading="CLOUD TRANSITION" />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default CloudTransition;
