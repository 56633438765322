import React from "react";
import ServiceSection from "../../components/ServiceSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import CommonBanner from "../../components/CommonBanner";

const Services = () => {
  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner currentPage="Services" heading="Services" />
      <ServiceSection />
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default Services;
